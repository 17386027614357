import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"common-balances"},[_c('div',{staticClass:"table-wrapper"},[(_vm.headerData.length > 0)?_c('rcc-table',{attrs:{"is-fixed-header":true,"page":_vm.page,"page-count":_vm.pages,"items-per-page":50,"is-loading":_vm.isDataLoading,"headers":_vm.headerData,"rows":_vm.rows},on:{"update:page":function($event){_vm.page=$event},"filters-changed":_vm.handleFiltersChanged},scopedSlots:_vm._u([{key:"body",fn:function(){return _vm._l((_vm.rows),function(row,index){return _c('tr',{key:index},[_c('td',{class:['supplier', !row.isActive && 'supplier_inactive']},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('td',{staticClass:"total-cell"},[_c(VTooltip,{attrs:{"top":"","disabled":!row.summary.updated_at},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(row.summary.total)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$moment(row.summary.updated_at).format('DD.MM.YYYY HH:mm')))])])],1),_vm._l((_vm.headerData.slice(2)),function(ref){
var value = ref.value;
return _c('td',{key:value,class:_vm.getCellClasses(row.balances[value])},[_c(VTooltip,{attrs:{"top":"","disabled":!row.balances[value]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(row.balances[value])?_c('span',[_vm._v(" "+_vm._s(row.balances[value].balance || 0)+" ")]):_c('span',[_vm._v(" - ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getTooltipText(row.balances[value])))])])],1)})],2)})},proxy:true}],null,false,434713926)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }